<template>
    <div class="contaners">
        <!-- <img src="../../assets/content_img.png" /> -->
        <div class="content">
            <div class="header_btn">采集签名</div>
            <div class="content_box">
                <catSignature @signatureBtn="signatureBtn" :imgToken="imgToken" :ladorshow="ladorshow" @cloneImg="cloneImg" @imgUblod="imgUblod"></catSignature>
            </div>
            <div class="nextclass" @click="nextBtn">下一步</div>
        </div>
        
       <!-- 弹窗 -->
		<div class="box_pop" v-if="popShow">
			<div class="success_box">
                <div class="success_header">提示</div>
                <img class="success_img" src="../../assets/signIcon.png"/>
                <div class="text">签名成功</div>
                <div class="text1">请前往下一步</div>
                <div class="submit" @click="submit">确认</div>
            </div>
		</div>
    <toast v-if="toastShow" :config="toastConfig"></toast>
    </div>
</template>
<script type="">
import * as apiCommon from "@/api/common";
import catSignature from "../../components/catSignature.vue";
import toast from "../../components/toast.vue";
export default {
  data() {
    return {
      value: "",
      popShow: false,
      imgToken:'',
      img:'',
      toastConfig:{
        title:'请完成签名'
      },
      toastShow:false,
      ladorshow:true
    };
  },
  components: {
    catSignature,
    toast
  },
  created() {
    document.title = "电子签名";
    this.getToken();
  },
  methods: {
    cloneImg(){
      this.img = "",
      localStorage.removeItem("imgName");
    },
    signatureBtn(){
      this.toastShow = true;
        setTimeout(e=>{
          console.log(e)
          this.toastShow = false;
        },1000)
    },
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
      console.log(this.imgToken, "1223131");
    },
    imgUblod(img){
      this.img = img;
      this.popShow = true;
      
    },
    submit() {
      console.log(12312);
      this.popShow = false;
    },
    nextBtn() {
      console.log(this.img,"this.img")
      if(!this.img){
        // alert("请完成签名")
        this.toastShow = true;
        setTimeout(e=>{
          console.log(e)
          this.toastShow = false;
        },1000)
        return false;
      }
      localStorage.setItem("imgName", this.img);
      this.$router.push("/lador/InformationForm");
    }
  }
};
</script>
<style scoped>
:root {
  --van-toast-font-size:40px;
  --van-toast-max-width:100%
}
.header_btn {
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  margin: 40px auto;
}
.content {
  position: absolute;
  top: 30px;
  left: 0;
}
.contaners {
  width: 100%;
  height: 100vh;
}
.contaners img {
  width: 100%;
  height: 100%;
}
.content_box {
  width: 645px;
  height: 850px;
  background: #ffffff;
  border: 2px solid #ffffff;
  /* border: 2px solid #FFFFFF; */

  border-radius: 20px;
  margin: 0 53px;
  /* padding: 17px 24px;box-sizing: border-box; */
  color: #fff;
  font-size: 25px;
}
.content_box div {
  margin-bottom: 30px;
  line-height: 40px;
  letter-spacing: 3px;
}

.box_pop {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index:9999;
  background: rgba(0, 0, 0, 0.5);
}
.success_box {
  width: 593px;
  height: 604px;
  background: #ffffff;
  /* border: 1px solid #707070; */
  opacity: 1;
  border-radius: 36px;
  margin: 253px auto 0;
  text-align: center;
}
.success_header {
  width: 593px;
  height: 112px;
  background: #377eb3;
  opacity: 1;
  border-radius: 36px 36px 0px 0px;
  font-size: 50px;
  text-align: center;
  line-height: 112px;
  color: #ffffff;
}
.contaners .success_img {
  width: 166px;
  height: 166px;
  margin: 60px 214px 0;
}
.text {
  font-size: 35px;
  color: #2a7bb5;
  margin: 2px 0;
}
.text1 {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.5);
}
.submit {
  width: 145px;
  height: 56px;
  background: #377eb3;
  opacity: 1;
  border-radius: 10px;
  text-align: center;
  line-height: 56px;
  font-size: 25px;
  color: #fff;
  margin: 48px auto 0;
}
.nextclass {
  width: 378px;
  height: 64px;
  background: #ffffff;
  border: 1px solid #707070;
  opacity: 1;
  border-radius: 41px;
  text-align: center;
  line-height: 64px;
  font-size: 35px;
  color: #2a7bb5;
  margin: 80px auto 0;
  position: relative;
  z-index: 10
}
</style>
